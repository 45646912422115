

var responsivetable = {
    init: function(){
        var screenwidth = $(window).outerWidth()

        if (screenwidth <= 1599) {
            $("table.myStacktable td").each(function () {
                var headerText = $(this).attr('headers');
                $(this).prepend("<label>" + headerText + "</label>");
            })
        }
        else {
            $('header').removeClass('shrink');
        }
    }
}