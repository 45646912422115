var collapse = {
    init: function () {
        var coll = document.getElementsByClassName("js-collapse");
        var i;
        for (i = 0; i < coll.length; i++) {
            coll[i].addEventListener("click", function(event) {

                // this.classList.toggle("js-collapse-open");
                var content = this.nextElementSibling;

                // if (this.hasAttribute('aria-expanded') === "false") {
                //     alert('ARIA EXPANDED')
                //     content.classList.add("mystyle");
                // }

                if (content.style.maxHeight){
                    content.style.maxHeight = null;
                } 
                else {
                    // content.style.maxHeight = content.scrollHeight + "px";
                    content.style.maxHeight = "none"; // we zetten dit op none, omdat wegens submenus een vaste hoogte een deel van het menu verbergt bij openklappen
                    event.preventDefault();
                    // content.classList.toggle("open");
                }
            });
        }
        var collOpen = document.getElementsByClassName("open");
        $( collOpen ).click(function() {
            $(this).removeClass('open');
            $(this).next().removeClass('open');
          });
    }
}