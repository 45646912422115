$(document).ready(function(){
    // console.log('document ready - it works');
})
window.onload = function(){
    // console.log("window onload - it works")
    accordion.init();
    backtotop.init();
    collapse.init();
    hovercollapse.init();
    // dropdown.init();
    fixedheader.init();
    // mobileFilter.init();
    // responsivetable.init();  
    search.init();  
    // toast.init(); 
    // youtubesetsource.init(); // moet na fixedheader ingeladen worden
}
$(window).resize(function() {
// $( window ).on( "orientationchange", function( event ) {
    fixedheader.init();  
    // mobileFilter.init(); 
  });


